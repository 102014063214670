<template>
  <div class="page page--events events">

    <template v-if="activeEvent">
      <div class="events__booking">
        <h1>{{ activeEvent.NAAM }} - {{ activeEvent.DEDATUM }}</h1>
        <div>
          <button class="button" :class="{'button--disabled':paymentLoading}" @click="activeEvent=null">
            {{ $t('back') }}
          </button>
        </div>
        <div class="events__booking__data">
          <h3>{{ $t('events.general') }}</h3>
          <div class="events__booking__data__field">
            <label>{{ $t('booking.transportationtype') }}</label>
            <select class="select" v-model="bookingInput.type">
              <option v-for="type in types" :key="type.label" :value="type.value">
                {{ $t('booking.' + type.value.toLowerCase()) }}
              </option>
            </select>
            <p v-html="$t('vervoer.'+bookingInput.type.toLowerCase())"></p>
          </div>
          <div class="events__booking__data__field">
            <label>{{ $t('booking.persons') }}*</label>
            <input class="number" type="number" min="1" max="7" v-model="bookingInput.pax"
                   @focusout="handleNumberInput($event,'pax')">
          </div>

          <div class="events__booking__data__field">
            <div class="booking__form__field">
              <div id="progress">
                <div id="progress-inner" :class="percentageClass"></div>
              </div>
            </div>
          </div>

          <div class="events__booking__data__field events__booking__data__field--settings">
            <div v-if="baggageSettings && baggageSettings.indexOf('KOFFERS')>-1">
              <label>{{ $t('booking.suitcases') }} <span class="sup">({{ $t('booking.handexcl') }})</span></label>
              <input type="number" class="number" min="0" max="8" v-model="bookingInput.koffers"
                     :class="{'error':cargo>MAX_CARGO}"
                     @focusout="handleNumberInput($event,'koffers')">
            </div>
            <div v-if="baggageSettings && baggageSettings.indexOf('FIETSKOFFERS')>-1">
              <label>{{ $t('booking.bike') }}</label>
              <input type="number" class="number" min="0" max="3" v-model="bookingInput.fietskoffers"
                     :class="{'error':cargo>MAX_CARGO}"
                     @focusout="handleNumberInput($event,'fietskoffers')">
            </div>
            <div v-if="baggageSettings && baggageSettings.indexOf('ROLSTOELEN')>-1">
              <label>{{ $t('booking.wheelchairs') }} <span class="sup">{{ $t('booking.folding') }}</span></label>
              <input type="number" class="number" min="0" max="3" v-model="bookingInput.rolstoelen"
                     :class="{'error':cargo>MAX_CARGO}"
                     @focusout="handleNumberInput($event,'rolstoelen')">
            </div>
            <div v-if="baggageSettings && baggageSettings.indexOf('ROLLATORS')>-1">
              <label>{{ $t('booking.rollator') }}</label>
              <input type="number" class="number" min="0" max="3" v-model="bookingInput.rollators"
                     :class="{'error':cargo>MAX_CARGO}"
                     @focusout="handleNumberInput($event,'rollators')">
            </div>
            <div v-if="baggageSettings && baggageSettings.indexOf('KINDERWAGENS')>-1">
              <label>{{ $t('booking.kinderwagens') }}</label>
              <input type="number" class="number" min="0" max="8" v-model="bookingInput.kinderwagens"
                     :class="{'error':cargo>MAX_CARGO}"
                     @focusout="handleNumberInput($event,'kinderwagens')">
            </div>
            <div v-if="baggageSettings && baggageSettings.indexOf('KINDERSTOELEN')>-1">
              <label>{{ $t('booking.kinderstoelen') }} <span class="sup">(max 2)</span></label>
              <input type="number" class="number" min="0" max="2" v-model="bookingInput.kinderstoelen"
                     :class="{'error':cargo>MAX_CARGO}"
                     @focusout="handleNumberInput($event,'kinderstoelen')">
            </div>
            <div v-if="baggageSettings && baggageSettings.indexOf('SKIS')>-1">
              <label>{{ $t('booking.skis') }}</label>
              <input type="number" class="number" min="0" max="8" v-model="bookingInput.skis"
                     :class="{'error':cargo>MAX_CARGO}"
                     @focusout="handleNumberInput($event,'skis')">
            </div>
            <div v-if="baggageSettings && baggageSettings.indexOf('GOLFBAGS')>-1">
              <label>{{ $t('booking.golfbags') }}</label>
              <input type="number" class="number" min="0" max="8" v-model="bookingInput.golfbags"
                     :class="{'error':cargo>MAX_CARGO}"
                     @focusout="handleNumberInput($event,'golfbags')">
            </div>
          </div>


          <h3>{{ $t('booking.addressinfo') }}</h3>
          <label class="checkbox" :class="{'disabled':!terug}"><b><span
              v-html="$t('booking.heenreis')"></span></b>
            <input type="checkbox" v-model="heen"/>
            <span class="checkbox__checkmark"></span>
          </label>
          <template v-if="heen">
            <button v-if="account" class="button" @click.prevent="onShowDepartureAddresses">
              {{ $t('account.chooseaddress') }}
            </button>
            <div class="booking__form__field">
              <label>{{ $t('booking.from') }}*</label>
              <input type="text" class="small" :class="{'error':!departureCityValid}"
                     :placeholder="$t('booking.postal')" v-model="bookingInput.addressH.zip"
                     @keyup="postalDepartureChanged">
              <span class="city" v-if="dCities">{{ dCities }} ({{ bookingInput.addressH.country }})</span>
            </div>
            <div class="booking__form__field">
              <input type="text" class="large" :class="{'error':!departureStreetValid}"
                     @keyup="departureStreetValid=true"
                     :placeholder="$t('booking.street')" v-model="bookingInput.addressH.street"
                     @focusin="departureStreetsVisible = true">
              <input type="text" class="tiny" :class="{'error':!departureNrValid}"
                     @keyup="departureNrValid=true"
                     :placeholder="$t('booking.nr')" v-model="bookingInput.addressH.nr">
              <div class="booking__form__streetcontainer"
                   v-if="bookingInput.addressH.street && departureStreetsVisible">
                <div v-for="street in departureStreetArray" :key="street.ID"
                     @click="onSelectDepartureStreet(street)">{{ `${street.STRAAT} (${street.STAD})` }}
                </div>
              </div>
            </div>
          </template>
          <label class="checkbox" :class="{'disabled':!heen}"><b><span
              v-html="$t('booking.terugreis')"></span></b>
            <input type="checkbox" v-model="terug"/>
            <span class="checkbox__checkmark"></span>
          </label>
          <template v-if="terug">
            <button v-if="heen" class="button" @click.prevent="sameAddressClicked">
              {{ $t('booking.sameaddress') }}
            </button>
            <button v-if="account" class="button" @click.prevent="onShowRetourAddresses">
              {{ $t('account.chooseaddress') }}
            </button>
            <div class="booking__form__field">
              <label>{{ $t('booking.retouraddress') }}*</label>

              <input type="text" class="small" :class="{'error':!retourCityValid}"
                     :placeholder="$t('booking.postal')" v-model="bookingInput.addressT.zip"
                     @keyup="postalRetourChanged">
              <span class="city" v-if="rCities">{{ rCities }} ({{ bookingInput.addressT.country }})</span>
            </div>
            <div class="booking__form__field">
              <input type="text" class="large" :class="{'error':!retourStreetValid}"
                     @keyup="retourStreetValid=true"
                     :placeholder="$t('booking.street')" v-model="bookingInput.addressT.street"
                     @focusin="retourStreetsVisible = true">
              <input type="text" class="tiny" :class="{'error':!retourNrValid}"
                     @keyup="retourNrValid=true"
                     :placeholder="$t('booking.nr')" v-model="bookingInput.addressT.nr">
              <div class="booking__form__streetcontainer"
                   v-if="bookingInput.addressT.street && retourStreetsVisible">
                <div v-for="street in retourStreetArray" :key="street.ID"
                     @click="onSelectRetourStreet(street)">{{
                    `${street.STRAAT}
                                    (${street.STAD})`
                  }}
                </div>
              </div>
            </div>
          </template>
          <h3>{{ $t('booking.personalia') }}</h3>
          <div class="events__booking__data__field">
            <label>{{ $t('booking.firstname') }}*</label>
            <input type="text" v-model="bookingInput.fname" @keyup="fNameValid=true"
                   :class="{'error':!fNameValid}">
            <label>{{ $t('booking.name') }}*</label>
            <input type="text" v-model="bookingInput.lname" @keyup="lNameValid=true"
                   :class="{'error':!lNameValid}">
            <label>{{ $t('booking.email') }}*</label>
            <input type="email" v-model="bookingInput.email" @keyup="mailValid=true"
                   :class="{'error':!mailValid}">
            <label>{{ $t('booking.phone') }}*</label>
            <vue-tel-input :preferredCountries="['BE','NL','FR','LU']" @input="phoneInputValid=true"
                           v-model="bookingInput.tel"
                           :class="{'error':!phoneInputValid}"
                           @validate="handlePhoneValidate"></vue-tel-input>
          </div>
          <div class="events__booking__data__field">
            <label class="checkbox checkbox--small" :class="{'checkbox--error':!gdprValid}">{{ $t('booking.gdpr1') }}
              <a :href="privacy" target="_blank">{{ $t('booking.gdpr2') }}</a>
              <input type="checkbox" v-model="bookingInput.gdprCompliant">
              <span class="checkbox__checkmark"></span>
            </label>
          </div>
          <div class="events__booking__data__field">
            <label class="checkbox checkbox--small" :class="{'checkbox--error':!termsValid}">{{ $t('booking.terms1') }}
              <router-link target="_blank" to="/terms">{{ $t('booking.terms2') }}</router-link>
              <input type="checkbox" v-model="bookingInput.termsCompliant">
              <span class="checkbox__checkmark"></span>
            </label>
          </div>
          <div class="events__booking__data__field">
            <label class="checkbox checkbox--small" :class="{'checkbox--error':!consentValid}">{{
                $t('booking.consent')
              }}
              <input type="checkbox" v-model="bookingInput.consentCompliant">
              <span class="checkbox__checkmark"></span>
            </label>
          </div>
        </div>
        <div class="events__booking__price" v-if="eventData">
          <h3>{{ $t('booking.overview') }}</h3>
          <p><b>{{ eventData.EVENTDATA.NAAM }} - {{ eventData.EVENTDATA.OMSCHRIJVING }} -
            {{ eventData.EVENTDATA.DATUMHEEN }} ({{ eventData.EVENTDATA.UURHEEN }})</b></p>
          <img v-if="activeEvent.FOTO" :src="activeEvent.FOTO" :alt="eventData.EVENTDATA.NAAM">
          <p>{{ $t('booking.personamount') }}: <b>{{ bookingInput.pax }}</b></p>
          <p>{{ $t('booking.transportationtype') }}: <b>{{ $t('booking.' + bookingInput.type.toLowerCase()) }}</b>
          </p>
          <div class="events__booking__price__column" v-if="heen">
            <h3>{{ $t('booking.heen') }}</h3>
            <div class="events__booking__price__block">
              <div class="events__booking__price__address">
                <p>{{ bookingInput.addressH.street }} {{ bookingInput.addressH.nr }}</p>
                <p>{{ bookingInput.addressH.zip }} {{ bookingInput.addressH.city }}</p>
              </div>
              <div class="events__booking__price__pointer events__booking__price__pointer--departure"></div>
              <div class="events__booking__price__address">
                <p>{{ eventData.LOCATIE.NAAM }}</p>
                <p>{{ arrivalTime }}</p>
              </div>
              <p>{{ eventData.EVENTDATA.DATUMHEEN }}</p>
              <p></p>
            </div>
          </div>
          <div class="events__booking__price__column" v-if="terug">
            <h3>{{ $t('booking.terug') }}</h3>
            <div class="events__booking__price__block">
              <div class="booking__overview__address">
                <p>{{ eventData.LOCATIE.NAAM }}</p>
                <p>{{ departureTime }}</p>
              </div>
              <div class="events__booking__price__pointer events__booking__price__pointer--arrival"></div>
              <div class="events__booking__price__address">
                <p>{{ bookingInput.addressT.street }} {{ bookingInput.addressT.nr }}</p>
                <p>{{ bookingInput.addressT.zip }} {{ bookingInput.addressT.city }}</p>
              </div>
              <p>{{ eventData.EVENTDATA.DATUMTERUG }}</p>
            </div>
          </div>
          <div class="events__booking__price__column" v-if="eventPrice">
            <h3>{{ $t('booking.price') }}</h3>
            <div class="events__booking__price__price" v-if="eventPrice && !priceLoading">
              <div class="events__booking__price__price__column" v-if="eventPrice.BRUTOPRIJSHEEN">
                <p><b>{{ $t('booking.heen') }}</b></p>
                <p class="price" v-if="eventPrice.BRUTOPRIJSHEEN !== priceDeparture">{{ $t('booking.bruto') }}:
                  <b>€{{ eventPrice.BRUTOPRIJSHEEN }}</b>
                </p>
                <p class="price price--redux" v-if="eventPrice.KORTINGHEEN">{{ $t('booking.reduction') }}: <b>-
                  €{{ eventPrice.KORTINGHEEN }}</b></p>
                <p class="price price--extra"
                   v-if="eventPrice.KORTINGHEEN && eventPrice.PRIJSKLANTHEEN && couponData.BETAALDDOORTRAVELAGENT">
                  {{ $t('booking.meerprijs') }}: <b>+ {{ eventPrice.PRIJSKLANTHEEN }}</b></p>
                <p class="price" :class="{'price--sum':eventPrice.KORTINGHEEN}">{{ $t('booking.topay') }}:
                  <b>€{{ priceDeparture }}</b>
                </p>
              </div>
              <div class="events__booking__price__price__column" v-if="eventPrice.BRUTOPRIJSTERUG">
                <p><b>{{ $t('booking.terug') }}</b></p>
                <p class="price" v-if="eventPrice.BRUTOPRIJSTERUG !== priceRetour">{{ $t('booking.bruto') }}:
                  <b>€{{ eventPrice.BRUTOPRIJSTERUG }}</b>
                </p>
                <p class="price price--redux" v-if="eventPrice.KORTINGTERUG">{{ $t('booking.reduction') }}: <b>-
                  €{{ eventPrice.KORTINGTERUG }}</b></p>
                <p class="price price--extra"
                   v-if="eventPrice.KORTINGTERUG && eventPrice.PRIJSKLANTTERUG && couponData.BETAALDDOORTRAVELAGENT">
                  {{ $t('booking.meerprijs') }}: <b>+ {{ eventPrice.PRIJSKLANTTERUG }}</b></p>
                <p class="price" :class="{'price--sum':eventPrice.KORTINGTERUG}">{{ $t('booking.topay') }}:
                  <b>€{{ priceRetour }}</b>
                </p>
              </div>
              <div class="price price--total">{{ $t('booking.totalprice') }}: <span>€{{ priceTotal }}</span>
              </div>
            </div>
            <div class="events__booking__price__loading" v-else-if="eventPrice && priceLoading">
              <img src="../assets/img/loading.svg" alt="calculating your price"/>
            </div>
            <!--<div class="events__booking__data__field">
                <label>{{$t('booking.coupon')}}</label>
                <input type="text" v-model="bookingInput.coupon" :class="couponClass">
            </div>-->
            <router-link to="/pricing" target="_blank">{{ $t('pricing.link') }}</router-link>
          </div>

        </div>
        <div class="events__booking__errors">
          <p class="error" v-if="heen && (!departureCityValid || !departureNrValid || !departureStreetValid)">
            {{ $t('errors.departureAddress') }}</p>
          <p class="error" v-if="terug && (!retourCityValid || !retourNrValid || !retourStreetValid)">
            {{ $t('errors.retourAddress') }}</p>
          <p class="error" v-if="!fNameValid">{{ $t('errors.firstname') }}</p>
          <p class="error" v-if="!lNameValid">{{ $t('errors.lastname') }}</p>
          <p class="error" v-if="!mailValid">{{ $t('errors.email') }}</p>
          <p class="error" v-if="!phoneInputValid">{{ $t('errors.phone') }}</p>
          <p class="error" v-if="!bookingInput.gdprCompliant && !gdprValid">{{ $t('errors.gdpr') }}</p>
          <p class="error" v-if="!bookingInput.termsCompliant && !termsValid">{{ $t('errors.terms') }}</p>
          <p class="error" v-if="!bookingInput.consentCompliant && !consentValid">{{ $t('errors.consent') }}</p>
        </div>
        <div class="events__booking__controls">
          <button class="booking__controls__btn booking__controls__btn--next"
                  :class="{'disabled':priceLoading||paymentLoading||!cargoValid}" @click="onBookClicked">
            {{ $t('booking.save') }}
          </button>
        </div>
      </div>
      <div class="popup" v-if="isPickupPopupVisible">
        <div class="popup__wrapper">
          <button class="popup__close" @click="isPickupPopupVisible=false">
            <fa-icon icon="times"></fa-icon>
          </button>
          <p class="popup__title">{{ $t('account.choosepickup') }}</p>
          <div class="popup__body">
            <ul class="list">
              <li v-for="item in accountAddresses" :key="'pickupaddress--'+item.id"
                  @click="onSelectAccountPickup(item)">
                {{ `${item.street} ${item.streetnr} - ${item.zip} ${item.city} (${item.country})` }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="popup" v-if="isRetourPopupVisible">
        <div class="popup__wrapper">
          <button class="popup__close" @click="isRetourPopupVisible=false">
            <fa-icon icon="times"></fa-icon>
          </button>
          <p class="popup__title">{{ $t('account.chooseretour') }}</p>
          <div class="popup__body">
            <ul class="list">
              <li v-for="item in accountAddresses" :key="'retouraddress--'+item.id"
                  @click="onSelectAccountRetour(item)">
                {{ `${item.street} ${item.streetnr} - ${item.zip} ${item.city} (${item.country})` }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <h1>{{ $t('events.title') }} {{ user ? `- ${user.url}` : '' }}</h1>
      <p>{{ $t('events.register1') }}
        <router-link to="/events-register">{{ $t('events.register2') }}</router-link>
        {{ $t('events.register3') }}
      </p>
      <div class="events__filters">
        <template v-if="queryUser && locations">
          <button v-for="item in locations" class="button" :key="item.id"
                  :class="{'events__filters__filter--active':loc===item.id}" @click="loc=item.id">
            {{ item.naam }}
          </button>
        </template>
        <template v-else>
          <button class="button" :class="{'events__filters__filter--active':loc===0}" @click="loc=0">
            {{ $t('events.all') }}
          </button>
          <button class="button" :class="{'events__filters__filter--active':loc===1}" @click="loc=1">
            {{ $t('events.sportpaleis') }}
          </button>
          <button class="button" :class="{'events__filters__filter--active':loc===2}" @click="loc=2">
            {{ $t('events.lottoarena') }}
          </button>
        </template>
      </div>
      <div class="events__list" v-if="events && hasEvents">
        <div class="events__list__item" v-for="item in events" :key="item.ID">
          <div class="events__list__item__image">
            <img v-if="item.FOTO" :src="item.FOTO" :alt="item.NAAM">
          </div>
          <div class="events__list__item__content">
            <p class="events__list__item__content__title">{{ item.NAAM }}</p>
            <p>{{ item.OMSCHRIJVING }}</p>
            <p>{{ item.DATUM }}</p>
            <p><b>{{ item.UURHEEN }}</b></p>
            <button class="button button--inverted" @click="startBooking(item)">{{ $t('events.book') }}
            </button>
          </div>
        </div>
      </div>
      <p v-else>{{ $t('events.none') }}</p>
    </template>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import PaymentService from "../services/payment";

export default {
  name: "Events",

  components: {
    VueTelInput
  },

  data() {
    return {
      MAX_CARGO: process.env.VUE_APP_BOOKING_MAXCARGO,
      privacy: process.env.VUE_APP_PRIVACY,
      filter: null,
      loc: 0,
      types: [
        //{label: "Combi", value: "COMBINATIE"},
        {label: "Comfort", value: "COMFORT"},
        {label: "Business", value: "BUSINESS"}
      ],
      activeEvent: null,
      bookingInput: {
        gdprCompliant: 0,
        termsCompliant: 0,
        consentCompliant: 0,
        pax: 1,
        koffers: 0,
        kinderstoelen: 0,
        kinderwagens: 0,
        skis: 0,
        golfbags: 0,
        rolstoelen: 0,
        rollators: 0,
        fietskoffers: 0,
        addressH: {
          street: '',
          nr: '',
          zip: '',
          city: '',
          country: ''
        },
        addressT: {
          street: '',
          nr: '',
          zip: '',
          city: '',
          country: ''
        },
        fname: '',
        lname: '',
        tel: '',
        email: '',
        type: 'COMFORT',
        eventid: '',
        coupon: '',
        distanceDeparture: "0",
        distanceRetour: "0",
      },
      heen: true,
      terug: true,
      phoneValid: true,
      phoneInputValid: true,
      couponValid: true,
      couponData: null,
      paymentMode: 'CREDITCARD',
      couponTimeout: null,
      mailValid: true,
      lNameValid: true,
      fNameValid: true,
      gdprValid: true,
      termsValid: true,
      consentValid: true,
      departureStreetValid: true,
      departureNrValid: true,
      departureCityValid: true,
      departureStreetArray: [],
      dCities: "",
      departureStreetsVisible: false,
      retourStreetValid: true,
      retourNrValid: true,
      retourCityValid: true,
      retourStreetArray: [],
      rCities: "",
      retourStreetsVisible: false,
      pickupTimeout: null,
      retourTimeout: null,
      priceTimeout: null,
      priceLoading: false,
      paymentLoading: false,
      eventData: null,
      isPickupPopupVisible: false,
      isRetourPopupVisible: false,

      queryUser: this.$route.params.organiser,
      user: null,
      locations: []
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  created() {
    if(this.token) {
      this.fetchInit();
    }
  },

  beforeDestroy() {
    clearTimeout(this.pickupTimeout);
    clearTimeout(this.retourTimeout);
    clearTimeout(this.couponTimeout);
    clearTimeout(this.priceTimeout);
  },

  watch: {
    token(){
      if(this.token){
        this.fetchInit();
      }
    },

    '$i18n.locale': function () {
      this.fetchEvents();
    },
    loc() {
      this.fetchEvents();
    },
    coupon() {
      this.handleCoupon();
    },

    heenterug() {
      this.locationsChanged();
    },
    'bookingInput.addressH': {
      handler() {
        clearTimeout(this.pickupTimeout);
        this.pickupTimeout = setTimeout(async() => {
          const postal = this.bookingInput.addressH.zip;
          let street = this.bookingInput.addressH.street;
          street = street ? street : "";
          if (postal) {
            try{
              const res = await this.$store.dispatch('booking/getStreets',{postal,street});
              this.dCities = res.metadata.citynames;
              this.departureStreetArray = res.data;
              if (!this.bookingInput.addressH.city) {
                this.bookingInput.addressH.city = res.data[0].STAD;
              }
              this.bookingInput.addressH.country = res.data[0].LAND;
              if (!this.bookingInput.addressT.zip) {
                this.bookingInput.addressT.zip = postal;
              }
            } catch(e){
              this.departureStreetArray = null;
              this.dCities = "";
            }
          } else {
            this.departureStreetArray = null;
            this.bookingInput.addressH.city = null;
            this.bookingInput.addressH.country = null;
            this.dCities = "";
          }
        }, 500);
      },
      deep: true
    },

    'bookingInput.addressT': {
      handler() {
        clearTimeout(this.retourTimeout);
        this.retourTimeout = setTimeout(async() => {
          const postal = this.bookingInput.addressT.zip;
          let street = this.bookingInput.addressT.street;
          street = street ? street : "";
          if (postal) {
            try{
              const res = await this.$store.dispatch('booking/getStreets',{postal,street});
              this.rCities = res.metadata.citynames;
              this.retourStreetArray = res.data;
              if (!this.bookingInput.addressT.city) {
                this.bookingInput.addressT.city = res.data[0].STAD;
              }
              this.bookingInput.addressT.country = res.data[0].LAND;
            } catch(e){
              this.retourStreetArray = null;
              this.rCities = "";
            }
          } else {
            this.retourStreetArray = null;
            this.bookingInput.addressT.city = null;
            this.bookingInput.addressT.country = null;
            this.rCities = "";
          }
        }, 500);
      },
      deep: true
    },

    'bookingInput.pax': {
      handler() {
        this.priceInputChanged();
      }
    },
    'bookingInput.koffers': {
      handler() {
        this.priceInputChanged();
      }
    },
    'bookingInput.kinderwagens': {
      handler() {
        this.priceInputChanged();
      }
    },
    'bookingInput.kinderstoelen': {
      handler() {
        this.priceInputChanged();
      }
    },
    'bookingInput.skis': {
      handler() {
        this.priceInputChanged();
      }
    },
    'bookingInput.golfbags': {
      handler() {
        this.priceInputChanged();
      }
    },
    'bookingInput.rolstoelen': {
      handler() {
        this.priceInputChanged();
      }
    },
    'bookingInput.rollators': {
      handler() {
        this.priceInputChanged();
      }
    },
    'bookingInput.fietskoffers': {
      handler() {
        this.priceInputChanged();
      }
    },

    'bookingInput.type': {
      handler() {
        this.priceInputChanged();
      }
    },

    'bookingInput.addressH.city': {
      handler() {
        this.locationsChanged();
      }
    },

    'bookingInput.addressT.city': {
      handler() {
        this.locationsChanged();
      }
    },

    'bookingInput.addressH.street': {
      handler() {
        if (this.departureStreetArray && this.departureStreetArray.some(s => s['STRAAT'].toLowerCase() === this.bookingInput.addressH.street.toLowerCase())) {
          this.locationsChanged();
        }
      }
    },

    'bookingInput.addressT.street': {
      handler() {
        if (this.retourStreetArray && this.retourStreetArray.some(s => s['STRAAT'].toLowerCase() === this.bookingInput.addressT.street.toLowerCase())) {
          this.locationsChanged();
        }
      }
    },
    fromheen() {
      this.locationsChanged()
    },
    toretour() {
      this.locationsChanged()
    },

    cargo() {
      this.setCargoProgress();
    }
  },

  computed: {
    token(){
      return this.$store.getters.token;
    },
    account(){
      return this.$store.getters['user/userData'];
    },
    events() {
      return this.$store.getters['event/events'];
    },
    hasEvents() {
      return this.$store.getters['event/hasEvents'];
    },
    eventPrice() {
      return this.$store.getters['event/eventPrice'];
    },
    accountAddresses() {
      return this.account ? this.account.addresses.filter(a => a.billoaddress) : null;
    },

    defaultAddress() {
      return this.account ? this.accountAddresses.find(a => a.defaultaddress) : null;
    },

    couponClass() {
      const coupon = this.bookingInput.coupon;
      if (!coupon || coupon === "") {
        return "";
      } else if (coupon && coupon !== "" && !this.couponValid) {
        return "error";
      } else if (coupon && coupon !== "" && this.couponValid) {
        return "valid";
      } else {
        return "";
      }
    },

    heenterug() {
      if (this.heen && this.terug) {
        return "HT";
      } else if (this.heen) {
        return "H";
      } else {
        return "T";
      }
    },

    fromheen() {
      const address = this.bookingInput.addressH;
      return address.zip && address.city && address.zip !== "" && address.city !== "" ? `${address.zip} ${address.city} ${address.country}` : '';
    },

    toretour() {
      const address = this.bookingInput.addressT;
      return address.zip && address.city && address.zip !== "" && address.city !== "" ? `${address.zip} ${address.city} ${address.country}` : '';
    },

    priceDeparture() {
      const prijs = this.eventPrice;
      if (this.coupon && this.couponData && this.couponData.BETAALDDOORTRAVELAGENT) {
        return prijs.KORTINGHEEN ? (parseFloat(prijs.BRUTOPRIJSHEEN) + parseFloat(prijs.PRIJSKLANTHEEN) - parseFloat(prijs.KORTINGHEEN)) : prijs.BRUTOPRIJSHEEN;
      } else if (this.coupon && this.couponData && !this.couponData.BETAALDDOORTRAVELAGENT) {
        return prijs.KORTINGHEEN ? (parseFloat(prijs.BRUTOPRIJSHEEN) - parseFloat(prijs.KORTINGHEEN)) : prijs.BRUTOPRIJSHEEN;
      } else {
        return prijs.KORTINGHEEN ? (parseFloat(prijs.BRUTOPRIJSHEEN) + parseFloat(prijs.PRIJSKLANTHEEN) - parseFloat(prijs.KORTINGHEEN)) : prijs.BRUTOPRIJSHEEN;
      }
    },

    priceRetour() {
      const prijs = this.eventPrice;
      if (this.coupon && this.couponData && this.couponData.BETAALDDOORTRAVELAGENT) {
        return prijs.KORTINGTERUG ? (parseFloat(prijs.BRUTOPRIJSTERUG) + parseFloat(prijs.PRIJSKLANTTERUG) - parseFloat(prijs.KORTINGTERUG)) : prijs.BRUTOPRIJSTERUG;
      } else if (this.coupon && this.couponData && !this.couponData.BETAALDDOORTRAVELAGENT) {
        return prijs.KORTINGTERUG ? (parseFloat(prijs.BRUTOPRIJSTERUG) - parseFloat(prijs.KORTINGTERUG)) : prijs.BRUTOPRIJSTERUG;
      } else {
        return prijs.KORTINGTERUG ? (parseFloat(prijs.BRUTOPRIJSTERUG) + parseFloat(prijs.PRIJSKLANTTERUG) - parseFloat(prijs.KORTINGTERUG)) : prijs.BRUTOPRIJSTERUG;
      }
    },

    priceTotal() {
      return parseFloat(this.priceDeparture) + parseFloat(this.priceRetour);
    },
    arrivalTime() {
      if (!this.eventData) {
        return null;
      }
      const time = this.eventData.EVENTDATA.UURHEEN;
      const margin = this.eventData.LOCATIE.URENVOORAF;
      const marginEnd = this.eventData.EVENTDATA.MARGEAANKOMST;
      const startDate = new Date();
      const tParts = time.split(':');
      startDate.setHours(parseInt(tParts[0]));
      startDate.setMinutes(parseInt(tParts[1]));
      startDate.setHours(startDate.getHours() - parseInt(margin), startDate.getMinutes() - (60 * (margin % 1)));
      const start = `${startDate.getHours() < 10 ? '0' : ''}${startDate.getHours()}:${startDate.getMinutes() < 10 ? '0' : ''}${startDate.getMinutes()}`;
      const endDate = new Date();
      endDate.setHours(startDate.getHours() + parseInt(marginEnd), startDate.getMinutes() + (60 * (marginEnd % 1)));
      const end = marginEnd ? ` - ${endDate.getHours() < 10 ? '0' : ''}${endDate.getHours()}:${endDate.getMinutes() < 10 ? '0' : ''}${endDate.getMinutes()}` : "";
      return `${start}${end}`;
    },
    departureTime() {
      if (!this.eventData) {
        return null;
      }
      const time = this.eventData.EVENTDATA.UURTERUG;
      const startDate = new Date();
      const marginEnd = this.eventData.EVENTDATA.MARGEVERTREK;
      const tParts = time.split(':');
      startDate.setHours(parseInt(tParts[0]));
      startDate.setMinutes(parseInt(tParts[1]));
      startDate.setHours(startDate.getHours() + parseInt(marginEnd), startDate.getMinutes() + (60 * (marginEnd % 1)));
      const end = marginEnd ? ` - ${startDate.getHours() < 10 ? '0' : ''}${startDate.getHours()}:${startDate.getMinutes() < 10 ? '0' : ''}${startDate.getMinutes()}` : "";
      return `${time}${end}`;
    },

    baggageSettings() {
      const ev = this.activeEvent;
      if (!ev) {
        return [];
      }
      const settings = JSON.parse(ev.SETTINGS);
      return settings.bagage ? settings.bagage : [];
    },

    cargo() {
      const input = this.bookingInput;
      return parseInt(input.pax) + parseInt(input.koffers) + parseInt(input.rolstoelen) * 5 + parseInt(input.skis) * 2 + parseInt(input.golfbags) * 2 + parseInt(input.rollators) * 5 + parseInt(input.fietskoffers) * 5 + parseInt(input.kinderwagens) * 2;
    },

    cargoValid() {
      const amountValid = parseInt(this.cargo) <= parseInt(this.MAX_CARGO);
      return amountValid && (parseInt(this.bookingInput.kinderstoelen) <= 2);
    },

    percentageClass() {
      return this.cargo / this.MAX_CARGO > 1 ? 'full' : '';
    }
  },

  methods: {

    fetchInit(){
      if (this.queryUser) {
        this.fetchOrganiser();
        return;
      }
      this.fetchEvents();
    },

    async fetchOrganiser() {
      const res = await this.$store.dispatch('event/getEventOrganiser', this.queryUser);
      if (res && res.user && res.addresses) {
        this.user = res.user;
        this.locations = res.addresses;
        this.loc = this.locations[0].id;
      } else {
        this.$router.replace('/events');
      }
      this.fetchEvents();
    },

    async fetchEvents() {
      const user = this.user ? this.user.user_id : 3;
      const data = {
        lang: this.$i18n.locale.toUpperCase(),
        user: user,
        location: this.loc
      }
      await this.$store.dispatch('event/getEvents', data);
    },

    startBooking(item) {
      this.activeEvent = item;
      this.getEventData(item.ID);
      this.resetBookingData();
      this.checkPrice();
      this.$nextTick(() => {
        this.setCargoProgress();
      }, 0);
    },

    async getEventData(id) {
      const res = await this.$store.dispatch('event/getEventData', id);
      if (res) {
        this.eventData = res;
      }
    },

    resetBookingData() {

      this.bookingInput = {
        gdprCompliant: 0,
        termsCompliant: 0,
        consentCompliant: 0,
        pax: 1,
        koffers: 0,
        kinderstoelen: 0,
        kinderwagens: 0,
        skis: 0,
        golfbags: 0,
        rolstoelen: 0,
        rollators: 0,
        fietskoffers: 0,
        addressH: {
          street: this.account ? this.defaultAddress.street : '',
          nr: this.account ? this.defaultAddress.streetnr : '',
          zip: this.account ? this.defaultAddress.zip : '',
          city: this.account ? this.defaultAddress.city : '',
          country: this.account ? this.defaultAddress.country : '',
        },
        addressT: {
          street: this.account ? this.defaultAddress.street : '',
          nr: this.account ? this.defaultAddress.streetnr : '',
          zip: this.account ? this.defaultAddress.zip : '',
          city: this.account ? this.defaultAddress.city : '',
          country: this.account ? this.defaultAddress.country : '',
        },
        fname: this.account ? this.account.user.firstname : '',
        lname: this.account ? this.account.user.lastname : '',
        tel: this.account ? this.account.user.phone : '',
        email: this.account ? this.account.user.email : '',
        type: 'COMFORT',
        eventid: this.activeEvent.ID,
        coupon: '',
        distanceDeparture: "0",
        distanceRetour: "0",
      };
      this.heen = true;
      this.terug = true;
      this.phoneValid = true;
      this.phoneInputValid = true;
      this.couponValid = true;
      this.mailValid = true;
      this.lNameValid = true;
      this.fNameValid = true;
      this.gdprValid = true;
      this.termsValid = true;
      this.consentValid = true;
      this.price = null;
      this.couponData = null;
      this.paymentMode = 'CREDITCARD';
    },

    mailValidate(email) {
      const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return email.match(regex);
    },

    handleNumberInput(event, key) {
      const max = parseInt(event.target.getAttribute('max'));
      const min = parseInt(event.target.getAttribute('min'));

      let value = event.target.value;
      if (!parseInt(value)) {
        this.bookingInput[key] = min;
      } else {
        if (parseInt(value) > max) {
          this.bookingInput[key] = max;
        }
        if (parseInt(value) < min) {
          this.bookingInput[key] = min;
        }
      }
    },

    handlePhoneValidate(value) {
      this.phoneValid = value.valid;
    },

    onSelectDepartureStreet(street) {
      this.bookingInput.addressH.street = street.STRAAT;
      this.bookingInput.addressH.city = street.STAD;
      this.departureStreetsVisible = false;
    },

    postalDepartureChanged() {
      this.departureCityValid = true;
      this.bookingInput.addressH.street = '';
      this.bookingInput.addressH.city = '';
      this.bookingInput.addressH.nr = '';
      this.dCities = '';
    },

    onSelectRetourStreet(street) {
      this.bookingInput.addressT.street = street.STRAAT;
      this.bookingInput.addressT.city = street.STAD;
      this.retourStreetsVisible = false;
    },

    postalRetourChanged() {
      this.retourCityValid = true;
      this.bookingInput.addressT.street = '';
      this.bookingInput.addressT.city = '';
      this.bookingInput.addressT.nr = '';
      this.rCities = '';
    },

    locationsChanged() {
      this.bookingInput.distanceDeparture = "0";
      this.bookingInput.distanceRetour = "0";
      clearTimeout(this.priceTimeout);
      this.priceTimeout = setTimeout(() => {
        this.checkPrice();
      }, 1000);
    },

    priceInputChanged() {
      clearTimeout(this.priceTimeout);
      this.priceTimeout = setTimeout(() => {
        this.checkPrice();
      }, 1000);
    },

    sameAddressClicked() {
      this.bookingInput.addressT = {...this.bookingInput.addressH};
      this.locationsChanged();
    },

    async checkPrice() {
      const input = this.bookingInput;
      const fromheen = this.bookingInput.addressH.street && this.departureStreetArray && this.departureStreetArray.length && this.departureStreetArray.some(s => s['STRAAT'].toLowerCase() === this.bookingInput.addressH.street.toLowerCase()) ? this.bookingInput.addressH.street + " " + this.fromheen : this.fromheen;
      const toretour = this.bookingInput.addressT.street && this.retourStreetArray && this.retourStreetArray.length && this.retourStreetArray.some(s => s['STRAAT'].toLowerCase() === this.bookingInput.addressT.street.toLowerCase()) ? this.bookingInput.addressT.street + " " + this.toretour : this.toretour;
      const data = {
        eventid: input.eventid,
        coupon: this.couponValid ? input.coupon : '',
        soortvervoer: input.type,
        pax: input.pax,
        koffers: input.koffers,
        kinderstoelen: input.kinderstoelen,
        kinderwagens: input.kinderwagens,
        skis: input.skis,
        golfbags: input.golfbags,
        rolstoelen: input.rolstoelen,
        rollators: input.rollators,
        fietskoffers: input.fietskoffers,
        heenterug: this.heenterug,
        afstandheen: input.distanceDeparture,
        fromheen,
        fromlandheen: input.addressH.country,
        toretour,
        afstandretour: input.distanceRetour,
        tolandretour: input.addressT.country
      };
      if (!data.eventid || (this.heen && !data.fromheen) || (this.terug && !data.toretour)) {
        return;
      }
      this.priceLoading = true;
      try {
        const res = await this.$store.dispatch('event/postEventPriceParams', data);
        if (res) {
          this.bookingInput.distanceDeparture = res.AFSTANDHEEN;
          this.bookingInput.distanceRetour = res.AFSTANDRETOUR;
        }
      } catch (e) {
        console.warn(e);
      }
      this.priceLoading = false;
    },

    resetValid() {
      this.phoneInputValid = true;
      this.couponValid = true;
      this.mailValid = true;
      this.lNameValid = true;
      this.fNameValid = true;
      this.gdprValid = true;
      this.termsValid = true;
      this.consentValid = true;
      this.departureStreetValid = true;
      this.departureNrValid = true;
      this.departureCityValid = true;
      this.retourStreetValid = true;
      this.retourNrValid = true;
      this.retourCityValid = true;
    },

    checkBookingValid() {
      this.resetValid();
      let errors = 0;
      const input = this.bookingInput;
      const addressH = this.bookingInput.addressH;
      const addressT = this.bookingInput.addressT;
      if (!this.cargoValid) {
        errors++;
      }
      if (!input.fname || input.fname === "") {
        this.fNameValid = false;
        errors++;
      }
      if (!input.lname || input.lname === "") {
        this.lNameValid = false;
        errors++;
      }
      if (!input.email || input.email === "" || !this.mailValidate(input.email)) {
        this.mailValid = false;
        errors++;
      }
      if (!input.tel || input.tel === "" || !this.phoneValid) {
        this.phoneInputValid = false;
        errors++;
      }
      if (this.heen && (!addressH.street || addressH.street === "" || !this.departureStreetArray || !this.departureStreetArray.some(s => s.STRAAT.toLowerCase() === addressH.street.toLowerCase()))) {
        this.departureStreetValid = false;
        errors++;
      }

      if (this.heen && (!addressH.nr || addressH.nr === "")) {
        this.departureNrValid = false;
        errors++;
      }

      if (this.heen && (!addressH.zip || addressH.zip === "" || !addressH.city || addressH.city === "")) {
        this.departureCityValid = false;
        errors++;
      }
      if (this.terug && (!addressT.street || addressT.street === "" || !this.retourStreetArray || !this.retourStreetArray.some(s => s.STRAAT.toLowerCase() === addressT.street.toLowerCase()))) {
        this.retourStreetValid = false;
        errors++;
      }

      if (this.terug && (!addressT.nr || addressT.nr === "")) {
        this.retourNrValid = false;
        errors++;
      }

      if (this.terug && (!addressT.zip || addressT.zip === "" || !addressT.city || addressT.city === "")) {
        this.retourCityValid = false;
        errors++;
      }
      if (!input.gdprCompliant) {
        this.gdprValid = false;
        errors++;
      }
      if (!input.termsCompliant) {
        this.termsValid = false;
        errors++;
      }
      if (!input.consentCompliant) {
        this.consentValid = false;
        errors++;
      }

      return !errors;
    },

    async onBookClicked() {
      const input = this.bookingInput;
      const data = {
        eventid: input.eventid,
        heenterug: this.heenterug,
        soortvervoer: input.type.toLowerCase(),
        taal: this.$i18n.locale.toUpperCase(),
        idbesteller: "",
        idaccount: this.account && this.account.user.id ? this.account.user.id : "",
        po: "",
        coupon: this.coupon,
        promo: this.couponData ? this.couponData.CAMPAGNE : "",
        pax: input.pax,
        heenstraat: input.addressH.street,
        heennr: input.addressH.nr,
        heenpostnr: input.addressH.zip,
        heenstad: input.addressH.city,
        heenland: input.addressH.country,
        afstandheen: input.distanceDeparture,
        reistijdheen: "",
        terugstraat: input.addressT.street,
        terugnr: input.addressT.nr,
        terugpostnr: input.addressT.zip,
        terugstad: input.addressT.city,
        terugland: input.addressT.country,
        afstandterug: input.distanceRetour,
        reistijdterug: "",
        voornaam: input.fname,
        naam: input.lname,
        telklant: input.tel,
        emailklant: input.email,
        talic: this.eventData.EVENTDATA.LINKKLANT,
        uid: "",
        emailagent: "",
        naamagent: "",
        brutoprijsheen: this.eventPrice ? this.eventPrice.BRUTOPRIJSHEEN : "",
        nettoprijsheen: this.eventPrice ? this.eventPrice.NETTOPRIJSHEEN : "",
        prijsklantheen: this.eventPrice ? this.eventPrice.PRIJSKLANTHEEN : "",
        brutoprijsterug: this.eventPrice ? this.eventPrice.BRUTOPRIJSTERUG : "",
        nettoprijsterug: this.eventPrice ? this.eventPrice.NETTOPRIJSTERUG : "",
        prijsklantterug: this.eventPrice ? this.eventPrice.PRIJSKLANTTERUG : ""
      };
      if (this.checkBookingValid()) {
        console.log(data);
        const res = await this.$store.dispatch('event/saveEventBooking', data);
        if (res) {
          this.paymentLoading = true;
          this.handleBookingResult(res);
        }
      }
    },

    async handleBookingResult(id) {
      const res = await this.$store.dispatch('booking/getBooking', id);
      if (res) {
        if (this.checkPaymentNeeded(res)) {
          this.preparePayment(res);
          return;
        }
        this.finishFreeOrder(res[0].BONNR);
      }
    },

    checkPaymentNeeded(booking) {
      let flag = false;
      if (booking && booking.length) {
        for (let i = 0; i < booking.length; i++) {
          if (parseFloat(booking[i].OPGAVEPRIJS) > 0) {
            flag = true;
            break;
          }
        }
      }
      return flag;
    },

    preparePayment(booking) {
      let price = 0;
      booking.forEach(b => {
        price += parseFloat(b.OPGAVEPRIJS);
      });
      const paymentSettings = PaymentService.preparePaymentData(booking[0].BONNR, booking[0].CUSTEMAIL, price, this.$i18n.locale);
      if (this.eventData.EVENTDATA.FAKTUUR) {
        this.paymentMode = 'INVOICE';
        this.finishInvoiceOrder(booking[0].BONNR);
      } else {
        this.setupIngenicoForm(paymentSettings);
      }

    },

    setupIngenicoForm(options) {
      const form = document.createElement("form");
      document.body.appendChild(form);
      form.method = "POST";
      form.action = options.actionUrl;
      form.id = "form-payment";

      const element1 = document.createElement("input");
      element1.name = "ACCEPTURL"
      element1.value = options.confirmUrl;
      element1.type = 'hidden'
      form.appendChild(element1);

      const element2 = document.createElement("input");
      element2.name = "AMOUNT"
      element2.value = options.price;
      element2.type = 'hidden'
      form.appendChild(element2);

      const element3 = document.createElement("input");
      element3.name = "CANCELURL"
      element3.value = options.cancelUrl;
      element3.type = 'hidden'
      form.appendChild(element3);

      const element4 = document.createElement("input");
      element4.name = "CURRENCY"
      element4.value = options.currency;
      element4.type = 'hidden'
      form.appendChild(element4);

      const element5 = document.createElement("input");
      element5.name = "DECLINEURL"
      element5.value = options.failUrl;
      element5.type = 'hidden'
      form.appendChild(element5);

      const element6 = document.createElement("input");
      element6.name = "EMAIL"
      element6.value = options.email;
      element6.type = 'hidden'
      form.appendChild(element6);

      const element7 = document.createElement("input");
      element7.name = "EXCEPTIONURL"
      element7.value = options.failUrl;
      element7.type = 'hidden'
      form.appendChild(element7);

      const element8 = document.createElement("input");
      element8.name = "LANGUAGE"
      element8.value = options.lang;
      element8.type = 'hidden'
      form.appendChild(element8);

      const element9 = document.createElement("input");
      element9.name = "ORDERID"
      element9.value = options.id;
      element9.type = 'hidden'
      form.appendChild(element9);

      const element10 = document.createElement("input");
      element10.name = "PSPID"
      element10.value = options.pspid;
      element10.type = 'hidden'
      form.appendChild(element10);

      const element11 = document.createElement("input");
      element11.name = "SHASIGN"
      element11.value = options.sign;
      element11.type = 'hidden'
      form.appendChild(element11);

      form.submit();
    },

    async finishFreeOrder(bonnr) {
      try{
        const res = await this.$store.dispatch('booking/handleFreeOrder', bonnr);
        window.location = res.redirect;
      }catch(e){
        console.warn(e);
      }
    },

    async finishInvoiceOrder(id) {
      try{
        const res = await this.$store.dispatch('booking/handleInvoice', id);
        window.location = res.redirect;
      }catch(e){
        console.warn(e);
      }
    },

    onShowDepartureAddresses() {
      this.isPickupPopupVisible = true;
    },

    onShowRetourAddresses() {
      this.isRetourPopupVisible = true;
    },

    onSelectAccountPickup(item) {
      this.bookingInput.addressH.street = item.street;
      this.bookingInput.addressH.zip = item.zip;
      this.bookingInput.addressH.city = '';
      this.bookingInput.addressH.nr = item.streetnr;
      this.isPickupPopupVisible = false;
      this.locationsChanged();
    },

    onSelectAccountRetour(item) {
      this.bookingInput.addressT.street = item.street;
      this.bookingInput.addressT.zip = item.zip;
      this.bookingInput.addressT.city = item.city;
      this.bookingInput.addressT.nr = item.streetnr;
      this.isRetourPopupVisible = false;
      this.locationsChanged();
    },

    setCargoProgress() {
      const bar = document.getElementById('progress-inner');
      if (this.cargo && bar) {
        let percentage = (this.cargo / this.MAX_CARGO) * 100;

        percentage = percentage > 100 ? 100 : percentage;
        bar.style.width = percentage + '%';
      }
    },

    async handleCoupon(){
      clearTimeout(this.couponTimeout);
      this.couponTimeout = setTimeout(async () => {
        const res = await this.$store.dispatch('booking/checkCoupon',this.coupon);
        this.couponValid = res.status;
        this.couponData = res.status ? res.data : null;
        this.locationsChanged()
      }, 500);
    }
  }
}
</script>